import axios from 'axios';
import { MP_CF_API_BASE_URL } from '../../apis/Constants';
import firebase from '../../firebaseConfig';

// Fetch the ID token
const fetchIdToken = async () => {
  const currentUser = firebase.auth().currentUser;
  if (currentUser) {
    return await currentUser.getIdToken();
  }
  return null;
};

// Create an axios instance
const axiosInstance = axios.create({
  baseURL: MP_CF_API_BASE_URL,
  responseType: 'json',
});

// Add a request interceptor to inject headers dynamically
axiosInstance.interceptors.request.use(async (config) => {
  const idToken = await fetchIdToken();
  const uid = localStorage.getItem('uid') || '';

  config.headers['Content-Type'] = 'application/json';
  config.headers['idToken'] = idToken || '';
  config.headers['uid'] = uid;

  if (idToken) {
    config.headers['Authorization'] = `Bearer ${idToken}`;
  }

  return config;
}, (error) => {
  // Handle request errors
  return Promise.reject(error);
});

export default axiosInstance;