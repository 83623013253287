import _ from "lodash";
import { getActiveStatusByEnvironment } from "./helper";
import { ENVIRONMENT } from "../Constants";

const AiDeenMenu = [
  {
    label: "Reset and Update Database",
    route: "/aideen",
    icon: "glyphicon glyphicon-paperclip",
    permission: 1,
  },
];

const consoleMenu = [
  {
    label: "Search user",
    route: "/search-user",
    icon: "glyphicon glyphicon-search",
    permission: 8,
  },
  {
    label: "Transfer premium",
    route: "/transfer-premium",
    icon: "glyphicon glyphicon-transfer",
    permission: 16,
  },
  {
    label: "Subscription history",
    route: "/subscription-history",
    icon: "glyphicon glyphicon-time",
    permission: 16,
  },
  {
    label: "Delete user logs",
    route: "/delete-user-logs",
    icon: "glyphicon glyphicon-trash",
    permission: 16,
    requireDataModeratorPermission: true,
  },
  {
    label: "Polls",
    route: "/polls",
    icon: "glyphicon glyphicon-tasks",
    permission: 16,
  },
];

const promoCodesMenu = [
  {
    label: "Search promo code",
    route: "/search-promo-code",
    icon: "glyphicon glyphicon-search",
    permission: 2,
  },
  {
    label: "One-time codes",
    route: "/onetime-promo-codes",
    icon: "glyphicon glyphicon-list-alt",
    permission: 2,
  },
  {
    label: "Multi-usage codes",
    route: "/multiusage-promo-codes",
    icon: "glyphicon glyphicon-list-alt",
    permission: 2,
  },
  // {
  //   label: "Gift A Premiums",
  //   route: "/list-gift-a-premiums",
  //   icon: "glyphicon glyphicon-list-alt",
  //   permission: 2
  // },
  {
    label: "Generate codes",
    route: "/generate-promo-codes",
    icon: "glyphicon glyphicon-save-file",
    permission: 4,
  },
  // {
  //   label: "One-time codes (Legacy)",
  //   route: "/onetime-promo-codes-legacy",
  //   icon: "glyphicon glyphicon-list-alt",
  //   permission: 2
  // }
];

const communityMenu = [
  {
    label: "Prayer Requests",
    route: "/prayer-requests",
    icon: "glyphicon glyphicon-th-list",
    permission: 1,
  },
  {
    label: "Prayer Times",
    route: "/prayer-times",
    icon: "glyphicon glyphicon-time",
    permission: 16,
  },
];

const contentMenu = [
  {
    label: "Create",
    route: "/content-create",
    icon: "glyphicon glyphicon-pencil",
    permission: 1,
  },
  {
    label: "List",
    route: "/content-list?language_code=en&content_type=all&category_id=all",
    icon: "glyphicon glyphicon-th-list",
    permission: 1,
  },
];

const contentV2Menu = [
  {
    label: "Create",
    route: "/content-v2-create",
    icon: "glyphicon glyphicon-pencil",
    permission: 1,
  },
  {
    label: "List",
    route: "/content-v2-list",
    icon: "glyphicon glyphicon-th-list",
    permission: 1,
  },
  {
    label: "Ramadan Contents",
    route: "/content-v2-list-ramadan-contents",
    icon: "glyphicon glyphicon-list-alt",
    permission: 1,
  },
  {
    label: "Featured/Pinned/Hidden Contents",
    route: "/content-v2-list-special-contents",
    icon: "glyphicon glyphicon-certificate",
    permission: 1,
  },
  {
    label: "Hashtags",
    route: "/content-v2-list-hashtags",
    icon: "glyphicon glyphicon-tag",
    permission: 1,
  },
  {
    label: "Trending Hashtags",
    route: "/content-v2-trending-hashtags",
    icon: "glyphicon glyphicon-tags",
    permission: 1,
  },
  {
    label: "Upload Contents Images",
    route: "/content-v2-images-upload",
    icon: "glyphicon glyphicon-upload",
    permission: 1,
  },
  {
    label: "Upload Contents Excel",
    route: "/content-v2-excel-upload",
    icon: "glyphicon glyphicon-list-alt",
    permission: 1,
  },
  {
    label: "Lesson Ingestion - Upload CSV",
    route: "/content-v2-lesson-ingestion",
    icon: "glyphicon  glyphicon-upload",
    permission: 1,
  },
];

const genericMenu = [
  {
    label: "General",
    route: "/general",
    icon: "glyphicon glyphicon-console",
    permission: 64,
  },
  {
    label: "Developer Options",
    route: "/developer-options",
    icon: "glyphicon glyphicon-cog",
    permission: 16,
  },
  {
    label: "Moderators",
    route: "/moderators",
    icon: "glyphicon glyphicon-user",
    permission: 1,
  },
  {
    label: "Logout",
    route: "/logout",
    icon: "glyphicon glyphicon-eject",
    permission: 1,
  },
];

const halalMenu = [
  {
    label: "Pending Places",
    route: "/halal-pending-places",
    icon: "glyphicon glyphicon-question-sign",
    permission: 8,
  },
  {
    label: "Pending Files",
    route: "/halal-pending-files",
    icon: "glyphicon glyphicon-question-sign",
    permission: 8,
  },
  {
    label: "Pending Claims",
    route: "/halal-pending-claims",
    icon: "glyphicon glyphicon-question-sign",
    permission: 8,
  },
  {
    label: "Approved Places",
    route: "/halal-approved-places",
    icon: "glyphicon glyphicon-ok-sign",
    permission: 8,
  },
  {
    label: "Approved Files",
    route: "/halal-approved-files?startWith=A&endBefore=B",
    icon: "glyphicon glyphicon-ok-sign",
    permission: 8,
  },
  {
    label: "Approved Ownership Claims",
    route: "/halal-approved-claims",
    icon: "glyphicon glyphicon-ok-sign",
    permission: 8,
  },
  {
    label: "Reports",
    route: "/halal-user-reports",
    icon: "glyphicon glyphicon-ban-circle",
    permission: 8,
  },
  {
    label: "Staff stats",
    route: "/halal-staff-stats",
    icon: "glyphicon glyphicon-stats",
    permission: 8,
  },
  {
    label: "Place statistics",
    route: "/halal-place-stats",
    icon: "glyphicon glyphicon-stats",
    permission: 8,
  },
];

const hajjAndUmrahMenu = [
  {
    label: "Create",
    route: "/hajj-and-umrah-create",
    icon: "glyphicon glyphicon-pencil",
    permission: 1,
  },
  {
    label: "List",
    route: "/hajj-and-umrah-list",
    icon: "glyphicon glyphicon-th-list",
    permission: 1,
  },
  {
    label: "Upload Images",
    route: "/hajj-and-umrah-images-upload",
    icon: "glyphicon glyphicon-upload",
    permission: 1,
  },
  {
    label: "Upload Excel",
    route: "/hajj-and-umrah-excel-upload",
    icon: "glyphicon glyphicon-list-alt",
    permission: 1,
  },
];

const hijriMenu = [
  {
    label: "Preview",
    route: "/hijri-info",
    icon: "glyphicon glyphicon-info-sign",
    permission: 1,
  },
  {
    label: "Update Ramadan Sightings",
    route: "/hijri-ramadan-sightings",
    icon: "glyphicon glyphicon-eye-open",
    permission: 1,
  },
  {
    label: "Update Eid ul Fitr Sightings",
    route: "/hijri-eid-ul-fitr-sightings",
    icon: "glyphicon glyphicon-eye-open",
    permission: 1,
  },
  {
    label: "Update Calendars",
    route: "/hijri-years",
    icon: "glyphicon glyphicon-calendar",
    permission: 1,
  },
  {
    label: "List File Versions",
    route: "/hijri-list-file-versions",
    icon: "glyphicon glyphicon-th-list",
    permission: 1,
  },
];

const otherMenu = [
  {
    label: "Ecommerce",
    route: "/ecommerce",
    icon: "glyphicon glyphicon-shopping-cart",
    permission: 1,
  },
];

const redisMenu = [
  {
    label: "Info - CFs",
    route: "/redis-info-cfs",
    icon: "glyphicon glyphicon-stats",
    permission: 1,
  },
  {
    label: "Info - API V2",
    route: "/redis-info-api-v2",
    icon: "glyphicon glyphicon-stats",
    permission: 1,
  },
  {
    label: "Flush Cache",
    route: "/redis-flush-cache",
    icon: "glyphicon glyphicon-flash",
    permission: 1,
  },
];

const precalcMenu = [
  {
    label: "Validate or Push",
    route: "/precalc",
    icon: "glyphicon glyphicon-open",
    permission: 32,
  },
  {
    label: "Update Precalc List",
    route: "/update-precalcs-list",
    icon: "glyphicon glyphicon-open-file",
    permission: 32,
  },
  {
    label: "Test Precalc API",
    route: "/test-precalc-api",
    icon: "glyphicon glyphicon-eye-open",
    permission: 32,
  },
  {
    label: "List Precalc File Versions",
    route: "/list-precalc-file-versions",
    icon: "glyphicon glyphicon-th-list",
    permission: 32,
  },
  {
    label: "Clear Precalc Cache",
    route: "/clear-precalc-cache",
    icon: "glyphicon glyphicon-trash",
    permission: 32,
  },
];

const adUnitsMenu = [
  {
    label: "Create",
    route: "/ad-units-create",
    icon: "glyphicon glyphicon-pencil",
    permission: 1,
  },
  {
    label: "List",
    route: "/ad-units-list",
    icon: "glyphicon glyphicon-th-list",
    permission: 1,
  },
];

const timelinePromosMenu = [
  {
    label: "Create",
    route: "/timeline-promo-create",
    icon: "glyphicon glyphicon-pencil",
    permission: 1,
  },
  {
    label: "List",
    route: "/timeline-promo-list",
    icon: "glyphicon glyphicon-th-list",
    permission: 1,
  },
  {
    label: "Upload Images",
    route: "/timeline-promo-images-upload",
    icon: "glyphicon  glyphicon-upload",
    permission: 1,
  },
  {
    label: "Upload Excel",
    route: "/timeline-promo-excel-upload",
    icon: "glyphicon glyphicon-list-alt",
    permission: 1,
  },
];

const reportsMenu = [
  {
    label: "Reports",
    route: "/reports",
    icon: "glyphicon glyphicon-equalizer",
    permission: 1,
  },
];

const settingsMenu = [
  {
    label: "Update Timestamps",
    route: "/update-setting-timestamps",
    icon: "glyphicon glyphicon-time",
    permission: 1,
  },
  {
    label: "View/Update Raw File",
    route: "/update-settings-raw-file",
    icon: "glyphicon glyphicon-open-file",
    permission: 1,
  },
  {
    label: "Update Key-Values",
    route: "/update-settings-key-values",
    icon: "glyphicon glyphicon-open-file",
    permission: 1,
  },
  {
    label: "List File Versions",
    route: "/list-settings-file-versions",
    icon: "glyphicon glyphicon-th-list",
    permission: 1,
  },
  {
    label: "Test Settings API",
    route: "/test-settings-api",
    icon: "glyphicon glyphicon-eye-open",
    permission: 1,
  },
];

const quranPlaylistsMenu = [
  {
    label: "Featured List",
    route: "/quran-playlists/featured-list",
    icon: "glyphicon glyphicon-list-alt",
    permission: 1,
  },
  {
    label: "Bulk Upload Featured",
    route: "/quran-playlists/bulk-upload-featured",
    icon: "glyphicon glyphicon-upload",
    permission: 1,
  },
];

const quranQuotesMenu = [
  {
    label: "Verse of the day List",
    route: "/quran-quotes-list",
    icon: "glyphicon glyphicon-th-list",
    permission: 1,
  },
  {
    label: "Upload Excel",
    route: "/quran-quotes-excel-upload",
    icon: "glyphicon glyphicon-list-alt",
    permission: 1,
  },
];

const virtualToursMenu = [
  {
    label: "Upload Images",
    route: "/virtual-tours-images-upload",
    icon: "glyphicon glyphicon-th-list",
    permission: 1,
  },
  {
    label: "Upload Excel",
    route: "/virtual-tours-excel-upload",
    icon: "glyphicon glyphicon-list-alt",
    permission: 1,
  },
];

const mailGunEmailMenu = [
  {
    label: "Send emails",
    route: "/mg-send-emails",
    icon: "glyphicon glyphicon-envelope",
    permission: 8,
  },
  {
    label: "Tags",
    route: "/mg-email-tags",
    icon: "glyphicon glyphicon-tags",
    permission: 8,
  },
];

const subscriptionDashboardMenu = [
  {
    label: "Premium SKU Manager",
    route: "/premium-sku-manager",
    icon: "glyphicon glyphicon-tags",
    permission: 32,
  },
  {
    label: "Stats",
    route: "/subscription-stats",
    icon: "glyphicon glyphicon-stats",
    permission: 8,
  },
];

const qalBoxMenu = [
  {
    label: "Upload Media",
    route: "/qalbox-upload-media-items",
    icon: "glyphicon glyphicon-th-list",
    permission: 32,
  },
  {
    label: "Upload Display Categories",
    route: "/qalbox-upload-display-categories",
    icon: "glyphicon glyphicon-list-alt",
    permission: 8,
  },
  {
    label: "Upload Images",
    route: "/qalbox-upload-images",
    icon: "glyphicon glyphicon-upload",
    permission: 8,
  },
  {
    label: "Search Media",
    route: "/qalbox-search-media",
    icon: "glyphicon glyphicon-search",
    permission: 8,
  },
  {
    label: "Search Media V2",
    route: "/qalbox-search-media-v2",
    icon: "glyphicon glyphicon-search",
    permission: 8,
  },
  {
    label: "Qalbox Shorts",
    route: "/qalbox-shorts",
    icon: "glyphicon glyphicon-search",
    permission: 8,
  },
  {
    label: "Qalbox Brands",
    route: "/qalbox-brands",
    icon: "glyphicon glyphicon-search",
    permission: 8,
  },
  {
    label: "Qalbox Ad Units",
    route: "/qalbox-ad-units",
    icon: "glyphicon glyphicon-search",
    permission: 8,
  },
  {
    label: "Qalbox Media Access",
    route: "/qalbox-media-access",
    icon: "glyphicon glyphicon-search",
    permission: 8,
  },
  {
    label: "Qalbox Organization",
    route: "/qalbox-organization",
    icon: "glyphicon glyphicon-search",
    permission: 8,
  },
  {
    label: "Qalbox Panelist",
    route: "/qalbox-panelist",
    icon: "glyphicon glyphicon-search",
    permission: 8,
  },
  {
    label: "Qalbox Events",
    route: "/qalbox-events",
    icon: "glyphicon glyphicon-search",
    permission: 8,
  },
  {
    label: "Genres",
    route: "/qalbox-genres",
    icon: "glyphicon glyphicon-th-list",
    permission: 8,
  },
  {
    label: "Media Tags",
    route: "/qalbox-media-tags",
    icon: "glyphicon glyphicon-th-list",
    permission: 8,
  },
  {
    label: "Display Categories",
    route: "/qalbox-display-categories",
    icon: "glyphicon glyphicon-search",
    permission: 8,
  },
  {
    label: "Chat Moderation",
    route: "/chat",
    icon: "glyphicon glyphicon-user",
    permission: 8,
  },

  {
    label: "Live Streamers",
    route: "/qalbox-streamers",
    icon: "glyphicon glyphicon-facetime-video",
    permission: 8,
  },
];

const dailyDeensMenu = [
  {
    label: "View & Update Content",
    route: "/update-daily-deens-content",
    icon: "glyphicon glyphicon-list",
    permission: 1,
  },
  {
    label: "Update Content from CSV",
    route: "/update-daily-deens-content-csv",
    icon: "glyphicon glyphicon-paperclip",
    permission: 1,
  },
];

const toolsMenu = [
  {
    label: "Image Uploader",
    route: "/tools-upload-images",
    icon: "glyphicon glyphicon-upload",
    permission: 1,
  },
];

const AllMenus = [
  {
    menu: consoleMenu,
    label: "Users",
    icon: "user",
    active: getActiveStatusByEnvironment({
      activeEnvs: [
        ENVIRONMENT.PRODUCTION,
        ENVIRONMENT.STAGING,
        ENVIRONMENT.DEVELOPMENT,
      ],
    }),
  },
  {
    menu: promoCodesMenu,
    label: "Promo Codes",
    icon: "ticket",
    active: getActiveStatusByEnvironment({
      activeEnvs: [
        ENVIRONMENT.PRODUCTION,
        ENVIRONMENT.DEVELOPMENT,
        ENVIRONMENT.STAGING,
      ],
    }),
  },
  {
    menu: subscriptionDashboardMenu,
    label: "Subscription Dashbaord",
    icon: "ticket",
    active: getActiveStatusByEnvironment({
      activeEnvs: [ENVIRONMENT.PRODUCTION, ENVIRONMENT.DEVELOPMENT],
    }),
  },
  {
    menu: communityMenu,
    label: "Community Moderation",
    icon: "rss",
    active: getActiveStatusByEnvironment({
      activeEnvs: [ENVIRONMENT.PRODUCTION, ENVIRONMENT.DEVELOPMENT],
    }),
  },
  {
    menu: reportsMenu,
    label: "Reports",
    icon: "pie-chart",
    active: getActiveStatusByEnvironment({
      activeEnvs: [ENVIRONMENT.PRODUCTION, ENVIRONMENT.DEVELOPMENT],
    }),
  },
  {
    menu: contentMenu,
    label: "Content",
    icon: "newspaper",
    active: false,
  },
  {
    menu: contentV2Menu,
    label: "ContentV2",
    icon: "image",
    active: getActiveStatusByEnvironment({
      activeEnvs: [
        ENVIRONMENT.PRODUCTION,
        ENVIRONMENT.STAGING,
        ENVIRONMENT.DEVELOPMENT,
      ],
    }),
  },
  {
    menu: qalBoxMenu,
    label: "QalBox",
    icon: "video",
    active: getActiveStatusByEnvironment({
      activeEnvs: [
        ENVIRONMENT.PRODUCTION,
        ENVIRONMENT.STAGING,
        ENVIRONMENT.DEVELOPMENT,
      ],
    }),
  },
  {
    menu: hajjAndUmrahMenu,
    label: "Hajj and Umrah",
    icon: "plane",
    active: getActiveStatusByEnvironment({
      activeEnvs: [ENVIRONMENT.PRODUCTION, ENVIRONMENT.DEVELOPMENT],
    }),
  },
  {
    menu: precalcMenu,
    label: "Precalc",
    icon: "clock",
    active: getActiveStatusByEnvironment({
      activeEnvs: [ENVIRONMENT.PRODUCTION, ENVIRONMENT.DEVELOPMENT],
    }),
  },
  {
    menu: hijriMenu,
    label: "Hijri",
    icon: "calendar",
    active: getActiveStatusByEnvironment({
      activeEnvs: [ENVIRONMENT.PRODUCTION, ENVIRONMENT.DEVELOPMENT],
    }),
  },
  {
    menu: timelinePromosMenu,
    label: "Timeline Promos",
    icon: "bullhorn",
    active: getActiveStatusByEnvironment({
      activeEnvs: [ENVIRONMENT.PRODUCTION, ENVIRONMENT.DEVELOPMENT],
    }),
  },
  {
    menu: quranPlaylistsMenu,
    label: "Quran Playlists",
    icon: "headphones",
    active: getActiveStatusByEnvironment({
      activeEnvs: [ENVIRONMENT.PRODUCTION, ENVIRONMENT.DEVELOPMENT],
    }),
  },
  {
    menu: virtualToursMenu,
    label: "Virtual Tours",
    icon: "youtube-play",
    active: getActiveStatusByEnvironment({
      activeEnvs: [ENVIRONMENT.PRODUCTION, ENVIRONMENT.DEVELOPMENT],
    }),
  },
  {
    menu: quranQuotesMenu,
    label: "Verse of the Day",
    icon: "quote-right",
    active: getActiveStatusByEnvironment({
      activeEnvs: [ENVIRONMENT.PRODUCTION, ENVIRONMENT.DEVELOPMENT],
    }),
  },
  {
    menu: dailyDeensMenu,
    label: "Daily Deen",
    icon: "quote-right",
    active: getActiveStatusByEnvironment({
      activeEnvs: [
        ENVIRONMENT.PRODUCTION,
        ENVIRONMENT.DEVELOPMENT,
        ENVIRONMENT.STAGING,
      ],
    }),
  },
  {
    menu: mailGunEmailMenu,
    label: "Email notifications",
    icon: "envelope",
    active: getActiveStatusByEnvironment({
      activeEnvs: [ENVIRONMENT.PRODUCTION, ENVIRONMENT.DEVELOPMENT],
    }),
  },
  {
    menu: toolsMenu,
    label: "Tools",
    icon: "cogs",
    active: getActiveStatusByEnvironment({
      activeEnvs: [
        ENVIRONMENT.PRODUCTION,
        ENVIRONMENT.STAGING,
        ENVIRONMENT.DEVELOPMENT,
      ],
    }),
  },
  {
    menu: settingsMenu,
    label: "Settings",
    icon: "cogs",
    active: getActiveStatusByEnvironment({
      activeEnvs: [ENVIRONMENT.PRODUCTION, ENVIRONMENT.DEVELOPMENT],
    }),
  },
  {
    menu: adUnitsMenu,
    label: "Ad Units",
    icon: "adn",
    active: false,
  },
  {
    menu: halalMenu,
    label: "Halal Places",
    icon: "list",
    active: false,
  },
  {
    menu: redisMenu,
    label: "Redis",
    icon: "database",
    active: getActiveStatusByEnvironment({
      activeEnvs: [
        ENVIRONMENT.PRODUCTION,
        ENVIRONMENT.STAGING,
        ENVIRONMENT.DEVELOPMENT,
      ],
    }),
  },
  {
    menu: otherMenu,
    label: "Others",
    icon: "ellipsis-h",
    active: getActiveStatusByEnvironment({
      activeEnvs: [ENVIRONMENT.PRODUCTION, ENVIRONMENT.DEVELOPMENT],
    }),
  },
  {
    menu: genericMenu,
    label: "Console Settings",
    icon: "cog",
    active: getActiveStatusByEnvironment({
      activeEnvs: [
        ENVIRONMENT.PRODUCTION,
        ENVIRONMENT.STAGING,
        ENVIRONMENT.DEVELOPMENT,
      ],
    }),
  },
  {
    menu: AiDeenMenu,
    label: "AiDeen",
    icon: "user",
    active: true,
  },
];

const MenuList = _.filter(AllMenus, "active");
export default MenuList;
