import firebase from 'firebase/app';
import firebaseEnvConfigs from './configs/firebase';
import {
  WEB_APP_ENV,
} from './Constants';

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseEnvConfigs[WEB_APP_ENV]);
} else {
  firebase.app();
}

export default firebase;